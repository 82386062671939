// useDocumentTitle.js
import { useEffect } from 'react'

function useDocumentTitle(title = false) {

  useEffect(() => {
    document.title = title;
  }, [title]);

}

export default useDocumentTitle;