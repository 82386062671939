import React, { useEffect } from 'react';
import useDocumentTitle from "../../useDocumentTitle";
import abus from './AbUs.png';
import geto from './GeTo.png';
import ouar from './OuAr.png';
import "./About.css";

import { useTranslation } from 'react-i18next';

const About = () => {
    useDocumentTitle('BetStup | About');

    const { t, i18n } = useTranslation();

    useEffect(() => {
        const lng = navigator.language;
        i18n.changeLanguage(lng);
    }, [])

    return(
    <body className="Acontainer">
        <div className="Apure">
            <p className="ApureText">{t('About.Mission')} <br></br>{t('About.Innovation')}</p>

        </div>        

        <div className="Adesc">
            <div className="ABulletPoints">
                <div className="ALeaders">
                    <img src={abus} className='abus' alt='About Us'/>
                    <p>
                    {t('About.Abus')}
                    </p>
                    
                </div>

                <div className="ADedicated">
                    <img src={ouar} className='ouarMobile' alt='Our Approach'/>
                    <p>
                    {t('About.Ouar1')}
                    <br></br><br></br>
                    {t('About.Ouar2')}
                    <br></br><br></br>
                    {t('About.Ouar3')}
                    </p>
                    <img src={ouar} className='ouar' alt='Our Approach'/>
                </div>
                <div className="AQuality">
                    <img src={geto} className='geto' alt='Get In Touch'/>
                    <p>
                    {t('About.Geto')}
                    </p>
                </div>
            </div>
        </div>
    </body>
    );
};

export default About;