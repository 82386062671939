import React, { useEffect } from "react";
import logo from "./logo.png";
import "./Header.css";

import { useTranslation } from 'react-i18next';

function Header() {
  const [isNavExpanded, setIsNavExpanded] = React.useState(false);

  const { t, i18n } = useTranslation();

    useEffect(() => {
        const lng = navigator.language;
        i18n.changeLanguage(lng);
    }, [])

  return (
    <header>
      <nav className="btcon">
      <a href="/"><img src={logo} className="logo" alt="logo"/></a>
      <button
        className="hamburger"
        onClick={() => {
          setIsNavExpanded(!isNavExpanded);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
        <div className="nav-menu">
        <ul>
          <li><a href="/">{t("Header.Home")}</a></li>
          <li><a href="/about">{t("Header.About")}</a></li>
          <li><a href="/services">{t("Header.Services")}</a></li>
          <li><a href="/partners">{t("Header.Partners")}</a></li>
          <li className="contact"><a className="context" href="/contact">{t("Header.Contact")}</a></li>
        </ul>
        </div>
      {isNavExpanded ? (
      <div className="nav-expanded">
        <ul>
          <li><a href="/">{t("Header.Home")}</a></li>
          <li><a href="/about">{t("Header.About")}</a></li>
          <li><a href="/services">{t("Header.Services")}</a></li>
          <li><a href="/partners">{t("Header.Partners")}</a></li>
          <li className="contact expanded"><a className="context" href="/contact">{t("Header.Contact")}</a></li>
        </ul>
      </div>) : (<></>)

      }
      </nav>
    </header>
  );
}

export default Header;
