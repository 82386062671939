import React, { useEffect } from 'react';
import web from './web.png';
import seo from './seo.png';
import mobile from './mobile.png';
import useDocumentTitle from "../../useDocumentTitle";
import { Balancer } from "react-wrap-balancer";
import "./Home.css";

import { useTranslation } from 'react-i18next';

const Home = () => {
    useDocumentTitle('BetStup | Home');

    const { t, i18n } = useTranslation();

    useEffect(() => {
        const lng = navigator.language;
        // eslint-disable-next-line
        i18n.changeLanguage(lng);
    }, [])

    return(
    <body className="container">
        <div className="pure">
            <p className="pureText">{t('Home.Header')}</p>

        </div>        

        <div className="desc">
            <h1 className="detitle">{t('Home.Who')}</h1>
            <div className="BulletPoints">
                <div className="Leaders">
                    <Balancer>
                    <svg xmlns="http://www.w3.org/2000/svg" width="102" height="102" viewBox="0 0 102 102" fill="none" >
                    <path d="M35.0625 92.4375H66.9375M51 92.4375V66.9375M76.5 44.625C76.5 34.5366 76.484 17.8042 76.4761 12.75C76.4761 11.9046 76.1402 11.0939 75.5425 10.4961C74.9447 9.89832 74.134 9.5625 73.2886 9.5625L28.7054 9.6143C27.8617 9.61429 27.0525 9.94875 26.455 10.5444C25.8575 11.14 25.5205 11.9482 25.5179 12.7918C25.5179 18.8839 25.492 38.5249 25.492 44.625C25.492 57.4308 42.0272 66.9375 50.992 66.9375C59.9569 66.9375 76.5 57.4308 76.5 44.625Z" stroke="black" stroke-width="7" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M25.5 19.125H9.5625V22.3125C9.5625 33.3134 16.2463 44.625 25.5 44.625M76.5 19.125H92.4375V22.3125C92.4375 33.3134 85.7537 44.625 76.5 44.625" stroke="black" stroke-width="7" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <p style={{fontWeight:'bold'}}>{t('Home.Leaders')}</p>
                    <p style={{ justifyContent:'center',alignItems:'center',textAlign:'center'}}>
                    {t('Home.LText')}
                    </p>
                    </Balancer>
                </div>

                <div className="Dedicated">
                    <Balancer>
                    <svg xmlns="http://www.w3.org/2000/svg" width="135" height="135" viewBox="0 0 135 135" fill="none" >
                    <path d="M90.7031 73.8281L113.906 50.625M61.1719 56.9531L78.0469 73.8281M21.0938 84.375L48.5156 56.9531" stroke="black" stroke-width="7" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M120.234 50.625C123.729 50.625 126.562 47.7918 126.562 44.2969C126.562 40.8019 123.729 37.9688 120.234 37.9688C116.739 37.9688 113.906 40.8019 113.906 44.2969C113.906 47.7918 116.739 50.625 120.234 50.625Z" stroke="black" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M84.375 86.4844C87.8699 86.4844 90.7031 83.6512 90.7031 80.1562C90.7031 76.6613 87.8699 73.8281 84.375 73.8281C80.8801 73.8281 78.0469 76.6613 78.0469 80.1562C78.0469 83.6512 80.8801 86.4844 84.375 86.4844Z" stroke="black" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M54.8438 56.9531C58.3387 56.9531 61.1719 54.1199 61.1719 50.625C61.1719 47.1301 58.3387 44.2969 54.8438 44.2969C51.3488 44.2969 48.5156 47.1301 48.5156 50.625C48.5156 54.1199 51.3488 56.9531 54.8438 56.9531Z" stroke="black" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M14.7656 97.0312C18.2606 97.0312 21.0938 94.1981 21.0938 90.7031C21.0938 87.2082 18.2606 84.375 14.7656 84.375C11.2707 84.375 8.4375 87.2082 8.4375 90.7031C8.4375 94.1981 11.2707 97.0312 14.7656 97.0312Z" stroke="black" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <p style={{fontWeight:'bold'}}>{t('Home.Ded')}</p>
                    <p style={{marginBottom:50, justifyContent:'center',alignItems:'center',textAlign:'center'}}>
                    {t('Home.DText')}
                    </p>
                    </Balancer>
                </div>
                <div className="Quality">
                <Balancer>
                    <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" fill="none">
                    <path d="M90 39H57.75L48 9L38.25 39H6L32.25 57L22.125 87L48 68.25L73.875 87L63.75 57L90 39Z" stroke="black" stroke-width="7" stroke-linejoin="round"/>
                    </svg>
                    <p style={{fontWeight:'bold'}}>{t('Home.Quality')}</p>
                    <p style={{marginBottom:25, justifyContent:'center',alignItems:'center',textAlign:'center'}}>
                    {t('Home.QText')}
                    </p>
                    </Balancer>
                </div>
            </div>
        </div>

        <div className="srvcs">
            <h1 className='detitle'>{t('Home.Services')}</h1>
            <div className="BulletPoints">
                <div className="Leaders">
                    <Balancer>
                    <p style={{fontWeight:'bold', fontSize: 40,paddingBottom:25}}>{t('Home.Web')}</p>
                    <img alt='Web Development' src={web} className='imgs'/>
                    </Balancer>
                </div>

                <div className="Dedicated">
                    <Balancer>
                    <p style={{fontWeight:'bold', fontSize: 40}}>{t('Home.App')}</p>
                    <img alt='App Development' src={mobile} style={{width:'70%'}}/>
                    </Balancer>
                </div>
                <div className="Quality">
                <Balancer>
                    <p style={{fontWeight:'bold', fontSize: 40, marginTop:-40}}>{t('Home.SEO')}</p>
                    <img alt='Social Media Marketing and SEO' src={seo} className='imgs'/>
                    </Balancer>
                </div>
            </div>
        </div>

        <div className="dwnld">
            <h3 className="detitle">{t('Home.Contact')}</h3>
            <p style={{fontWeight:'bold', color:'#000'}}>BetStup on <a style={{fontWeight:'bold'}} href='https://www.instagram.com/betstup' target="_blank" rel="noreferrer" >Instagram</a> <span style={{fontWeight:'400'}}>|</span> <a href='mailto:info@betstup.com'>info@betstup.com</a></p>
            <div className="gplay">
            
            </div>
        </div>
    </body>
    );
};

export default Home;