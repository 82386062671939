import React, {useEffect} from 'react';
import useDocumentTitle from "../../useDocumentTitle";
import webd from './webd.png';
import appd from './appd.png';
import seod from './seod.png';
import "./Services.css";

import { useTranslation } from 'react-i18next';

const Services = () => {
    useDocumentTitle('BetStup | Services');

    const { t, i18n } = useTranslation();

    useEffect(() => {
        const lng = navigator.language;
        i18n.changeLanguage(lng);
    }, [])

    return(
    <body className="Scontainer">
        <div className="Spure">
            <p className="SpureText">{t('Services.Our')}</p>

        </div>        

        <div className="Sdesc">
            <div className="SBulletPoints">
                <div className="SLeaders">
                    <img src={webd} className='webd' alt='Web Development'/>
                    <p>
                    {t('Services.Web')}
                    </p>
                    
                </div>

                <div className="SDedicated">
                    <img src={appd} className='appd' alt='App Development'/>
                    <p>
                    {t('Services.App')}
                    </p>
                </div>
                <div className="SQuality">
                    <img src={seod} className='seod' alt='SMM and SEO'/>
                    <p>
                    {t('Services.SEO')}
                    </p>
                </div>
                <p className='pr'>
                {t('Services.Sum')}
                </p>
            </div>
        </div>
    </body>
    );
};

export default Services;