import React, { useEffect } from 'react';
import useDocumentTitle from "../../useDocumentTitle";
import "./Contact.css";

import { useTranslation } from 'react-i18next';

const Contact = () => {
    useDocumentTitle('BetStup | Contact Us');

    const { t, i18n } = useTranslation();

    useEffect(() => {
        const lng = navigator.language;
        i18n.changeLanguage(lng);
    }, [])

    return(
    <body className="Ccontainer">
        <div className="Cpure">
            <p className="CpureText">{t("Contact.Headline")}</p>
        </div>        

        <div className="Cdesc">
            <div className="CBulletPoints">
                <div className="CLeaders">
                    <p>
                    BetStup UG<br></br><br></br>
                    Bahnhofstraße 74 <br></br>
                    32257 Bünde, Germany <br></br>
                    info@betstup.com
                    <br></br>
                    <br></br>
                    Ridvan Akar
                    <br></br>
                    <br></br>
                    Business Form: UG<br></br>
                    Register court: Place of Jurisdiction Bünde<br></br>
                    Registernumber: HRB19087<br></br>
                    Tax-ID: 310/5707/2963
                    <br></br><br></br>
                    
                    
                    {t("Contact.Dino")}
                    <br></br>
                    {t("Contact.DinoText")}
                    <br></br><br></br>
                    {t("Contact.ConUs")}
                    <br></br>                                        
                    {t("Contact.ConUsText")}
                    </p>
                    
                </div>
            </div>
        </div>
    </body>
    );
};

export default Contact;