//BETSTUP.COM SOURCE CODE
//©BETSTUP UG. ALL RIGHTS RESERVED.
//CONFIDENTIAL - DO NOT SHARE THIS CODE!
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

//Importing Pages
import Contact from "./pages/Contact/Contact";
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Services from './pages/Services/Services';
import Partners from './pages/Partners/Partners';

//Fonts
import './fonts/PlayfairDisplay-VariableFont_wght.ttf';
import './fonts/Inter.ttf';
import './fonts/Inter-Light.ttf';
import './fonts/Inter-Medium.ttf';
import './fonts/Inter-Bold.ttf';
import './fonts/Inter-SemiBold.ttf';
import './fonts/Inter-Black.ttf';

function App() {
  return (
  <>
    <div className="App">
    <Router>
      <Routes>
        <Route exact path ="/" element={<Home/>} />
        <Route path='/about' element={<About />}/>
        <Route path='/services' element={<Services />}/>
        <Route path='/partners' element={<Partners />}/>
        <Route path='/contact' element={<Contact />}/>
        <Route path='*' element={<Home />}/>
      </Routes>
    </Router>
  </div>
  </>
  );
}

export default App;
