import "./Footer.css";

const getYear = () => {
    return new Date().getFullYear();
}

const News = () => {
    return(
    <footer className="foot">


        <div className="footer">
            <p style={{justifyContent:'center', alignItems:'center'}}>©{getYear()} BetStup UG. All Rights Reserved.</p>
        </div>
    </footer>
    );
};

export default News;