import React, { useEffect } from 'react';
import useDocumentTitle from "../../useDocumentTitle";
import prtnrs from './prtnrs.jpg';
import "./Partners.css";

import { useTranslation } from 'react-i18next';

const Partners = () => {
    useDocumentTitle('BetStup | Partners');

    const { t, i18n } = useTranslation();

    useEffect(() => {
        const lng = navigator.language;
        i18n.changeLanguage(lng);
    }, [])

    return(
    <body className="Pcontainer">
        <div className="Ppure">
            <p className="PpureText">{t('Partners.Headline')}</p>
        </div>        

        <div className="Pdesc">
            <div className="PBulletPoints">
                <div className="PLeaders">
                    <img src={prtnrs} className='webd' alt='Partners'/>
                    <p>
                    {t('Partners.Part1')}
                    <br></br><br></br>
                    {t('Partners.Part2')}
                    <br></br><br></br>
                    {t('Partners.Part3')}
                    </p>
                    
                </div>

                <div className="PDedicated">
                    <p className='trustingPartners'>{t('Partners.Working')}</p>
                    <p style={{color:'#606060'}}className='companies'>
                    Samsung | Apple | Google | TCL | Xiaomi | Sony | Philips | Sharp | Stripe
                    </p>
                </div>
            </div>
        </div>
    </body>
    );
};

export default Partners;